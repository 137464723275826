<template>
  <div
    id="mobile-navigation"
    class="mobile-navigation"
    :class="{ 'sticky-layout': stickyLayout }"
  >
    <div class="user-data">
      <div class="avatar-section">
        <profile-photo :height="45" :width="45" />
      </div>
      <div class="name-section">
        <div class="user-name">
          <div class="name-text mem-profile-title">
            {{ userData.first_name + " " + userData.last_name }}
          </div>
          <div
            class="user-status"
            v-if="userData && userData.spartan_plus_member"
          >
            Spartan+
          </div>
        </div>
      </div>
      <a
        v-if="showProfileUrl"
        :href="profileUrl"
        class="profile-link"
        target="_blank"
      >
        <img
          class="profile-icon"
          :src="require('@/assets/icon_circled_up_left.svg')"
        />
      </a>
    </div>
    <!-- important tabs -->
    <div class="profile-tabs" id="tabs">
      <div class="tabs-content">
        <div
          class="slider-wrapper"
          :style="{ width: `${borderWidth}px`, left: `${borderPosition}px` }"
        >
          <div class="tab-slider"></div>
        </div>
        <div
          v-if="showHomeLink"
          ref="homeTab"
          class="profile-tab"
          :class="{ 'active-tab': navLinks.home.includes($route.name) }"
          @click="navigateTo('home')"
        >
          <span>{{ $t("home") }}</span>
        </div>
        <div
          ref="eventsTab"
          class="profile-tab"
          :class="{ 'active-tab': navLinks.events.includes($route.name) }"
          @click="navigateTo('events')"
        >
          <span>{{ $t("events") }}</span>
          <div v-if="tickets.length !== 0" class="tab-counter">
            {{ tickets.length }}
          </div>
        </div>
        <div
          ref="resultsTab"
          class="profile-tab"
          :class="{ 'active-tab': navLinks.results.includes($route.name) }"
          @click="navigateTo('results')"
        >
          <span>{{ $t("results") + " & " + $t("Photos") }}</span>
        </div>
        <div
          ref="teamsTab"
          class="profile-tab"
          :class="{ 'active-tab': navLinks.teams.includes($route.name) }"
          @click="navigateTo('teamsList')"
        >
          <span>{{ $t("teams") }}</span>
        </div>
        <div
          ref="tropaionTab"
          class="profile-tab"
          :class="{ 'active-tab': navLinks.tropaion.includes($route.name) }"
          @click="navigateTo('tropaion')"
        >
          <span>{{ $t("tropaion") }}</span>
        </div>
        <div
          ref="benefitsTab"
          class="profile-tab"
          :class="{ 'active-tab': navLinks.benefits.includes($route.name) }"
          @click="navigateTo('benefits')"
        >
          <span>{{ $t("benefits") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uniq } from "lodash";
import { mapGetters } from "vuex";

import { UrlPath } from "@/common/helpers";
import localizeResolver from "@/common/mixins/localizeResolver";
import ProfilePhoto from "./ProfilePhoto.vue";

export default {
  name: "NavigationTabs",
  mixins: [localizeResolver],
  data: () => ({
    borderWidth: 0,
    borderPosition: 0,
    header: "",
    sticky: 110,
    stickyLayout: false,
    navLinks: {
      home: ["home"],
      events: ["events", "eventPage"],
      results: ["results", "resultPage"],
      teams: [
        "teamsList",
        "teamCreate",
        "teamEdit",
        "teamLeaderboard",
        "teamEvents",
      ],
      tropaion: ["tropaion", "challenges"],
      benefits: ["benefits", "UnbreakablePassPage", "SpartanPlusPage"],
    },
  }),
  components: {
    ProfilePhoto,
  },
  methods: {
    navigateTo(path) {
      this.$router.push({ name: path }).catch(() => {});
    },
    switchTab() {
      // TODO: Need to refactor this function

      const homeOffset = this.showHomeLink
        ? this.$refs?.homeTab?.offsetWidth
        : -32;

      if (this.navLinks.home.includes(this.$route.name)) {
        this.borderPosition = 0;
        this.borderWidth = 32 + homeOffset;
      }
      if (this.navLinks.events.includes(this.$route.name)) {
        this.borderPosition = 32 + homeOffset;
        this.borderWidth = 32 + this.$refs.eventsTab.offsetWidth;
      }
      if (this.navLinks.results.includes(this.$route.name)) {
        this.borderPosition =
          64 + homeOffset + this.$refs.eventsTab.offsetWidth;
        this.borderWidth = 32 + this.$refs.resultsTab.offsetWidth;
      }
      if (this.navLinks.teams.includes(this.$route.name)) {
        this.borderPosition =
          96 +
          homeOffset +
          this.$refs.eventsTab.offsetWidth +
          this.$refs.resultsTab.offsetWidth;
        this.borderWidth = 32 + this.$refs.teamsTab.offsetWidth;
      }
      if (this.navLinks.tropaion.includes(this.$route.name)) {
        this.borderPosition =
          128 +
          homeOffset +
          this.$refs.eventsTab.offsetWidth +
          this.$refs.resultsTab.offsetWidth +
          this.$refs.teamsTab.offsetWidth;
        this.borderWidth = 32 + this.$refs.tropaionTab.offsetWidth;
      }
      if (this.navLinks.benefits.includes(this.$route.name)) {
        this.borderPosition =
          160 +
          homeOffset +
          this.$refs.eventsTab.offsetWidth +
          this.$refs.resultsTab.offsetWidth +
          this.$refs.teamsTab.offsetWidth +
          this.$refs.tropaionTab.offsetWidth;
        this.borderWidth = 32 + this.$refs.benefitsTab.offsetWidth;
      }
    },
    scrollPage() {
      if (!document.getElementById("tabs")) return;
      else {
        if (window.pageYOffset >= this.sticky) {
          this.header.classList.add("sticky");
          this.stickyLayout = true;
        } else {
          this.header.classList.remove("sticky");
          this.stickyLayout = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["userData", "unassignedTickets"]),
    tickets() {
      return uniq(this.unassignedTickets);
    },
    lang() {
      return this.$i18n.locale;
    },
    showProfileUrl() {
      return ["US", "MX", "CA", "GB"].includes(this.userCountry);
    },
    profileUrl() {
      let homeUrl = this.localizeConfig?.homeUrl;
      return UrlPath.join(`/race/profile/${this.userData.id}`, homeUrl);
    },
    showHomeLink() {
      return ["US"].includes(this.userCountry);
    },
  },
  mounted() {
    this.switchTab();
    this.header = document.getElementById("tabs");
  },
  watch: {
    $route() {
      this.switchTab();
    },
    lang() {
      setTimeout(() => {
        this.switchTab();
      }, 0);
    },
  },
  created() {
    window.addEventListener("resize", this.switchTab);
    window.addEventListener("scroll", this.scrollPage, { passive: true });
  },
  destroyed() {
    window.removeEventListener("resize", this.switchTab);
    window.removeEventListener("scroll", this.scrollPage, { passive: true });
  },
};
</script>
<style lang="scss" scoped>
.sticky {
  position: fixed;
  top: 65px;
  z-index: $zindex-page-sticky-el;
}

.sticky-layout {
  padding-bottom: 46px;
}

.active-tab {
  color: #ffffff;
}

.mobile-navigation {
  display: none;
  background-color: #020000;
  @media screen and (max-width: $mobile) {
    display: block;
  }

  .user-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 30px;
    @media screen and (max-width: 375px) {
      padding: 30px 16px;
    }

    .avatar-section {
      display: grid;
      justify-content: center;
      margin-right: 16px;
    }

    .name-section {
      overflow: hidden;

      .user-name {
        white-space: nowrap;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        .name-text {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .user-status {
          font-weight: bold;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #cf1019;
          margin-top: 2px;
        }
      }
    }

    .profile-link {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #1d1d1d;
      margin-left: auto;
    }
  }
}

.profile-tabs {
  display: flex;
  height: 42px;
  background-color: #020000;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  .tabs-content {
    display: flex;
    position: relative;

    .slider-wrapper {
      position: absolute;
      height: 3px;
      bottom: 0;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

      .tab-slider {
        height: 100%;
        width: 100%;
        background-color: #ffffff;
      }
    }

    @media screen and (max-width: 499px) {
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.profile-tab {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  color: #9d9d9d;
  margin: 0 16px;
  @include cursorPointer;

  .tab-counter {
    position: absolute;
    top: 6px;
    right: -11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    background-color: #cc092f;
    border-radius: 100px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.0125em;
    color: #ffffff;
  }
}
</style>
