import { render, staticRenderFns } from "./TheNavigationTabs.vue?vue&type=template&id=aef2bcf8&scoped=true"
import script from "./TheNavigationTabs.vue?vue&type=script&lang=js"
export * from "./TheNavigationTabs.vue?vue&type=script&lang=js"
import style0 from "./TheNavigationTabs.vue?vue&type=style&index=0&id=aef2bcf8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef2bcf8",
  null
  
)

export default component.exports